exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-common-problems-in-db-design-lifecycle-js": () => import("./../../../src/pages/blog/common-problems-in-db-design-lifecycle.js" /* webpackChunkName: "component---src-pages-blog-common-problems-in-db-design-lifecycle-js" */),
  "component---src-pages-blog-issues-with-homomorphic-encryption-js": () => import("./../../../src/pages/blog/issues-with-homomorphic-encryption.js" /* webpackChunkName: "component---src-pages-blog-issues-with-homomorphic-encryption-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-main-subsystems-comprising-every-dbms-js": () => import("./../../../src/pages/blog/main-subsystems-comprising-every-dbms.js" /* webpackChunkName: "component---src-pages-blog-main-subsystems-comprising-every-dbms-js" */),
  "component---src-pages-blog-most-used-resource-management-solutions-js": () => import("./../../../src/pages/blog/most-used-resource-management-solutions.js" /* webpackChunkName: "component---src-pages-blog-most-used-resource-management-solutions-js" */),
  "component---src-pages-blog-problems-with-mlops-js": () => import("./../../../src/pages/blog/problems-with-mlops.js" /* webpackChunkName: "component---src-pages-blog-problems-with-mlops-js" */),
  "component---src-pages-blog-security-top-5-most-popular-data-search-systems-js": () => import("./../../../src/pages/blog/security-top-5-most-popular-data-search-systems.js" /* webpackChunkName: "component---src-pages-blog-security-top-5-most-popular-data-search-systems-js" */),
  "component---src-pages-blog-top-ml-execution-strategies-js": () => import("./../../../src/pages/blog/top-ml-execution-strategies.js" /* webpackChunkName: "component---src-pages-blog-top-ml-execution-strategies-js" */),
  "component---src-pages-blog-top-scenarios-where-serverless-computing-fails-js": () => import("./../../../src/pages/blog/top-scenarios-where-serverless-computing-fails.js" /* webpackChunkName: "component---src-pages-blog-top-scenarios-where-serverless-computing-fails-js" */),
  "component---src-pages-compliance-evaluator-js": () => import("./../../../src/pages/compliance-evaluator.js" /* webpackChunkName: "component---src-pages-compliance-evaluator-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-domain-scanner-js": () => import("./../../../src/pages/domain-scanner.js" /* webpackChunkName: "component---src-pages-domain-scanner-js" */),
  "component---src-pages-fines-js": () => import("./../../../src/pages/fines.js" /* webpackChunkName: "component---src-pages-fines-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

